.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__progress-count, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__submitted-count {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__status, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__footer__status-indicator-list__item {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__status, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__footer__status-indicator-list__item {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__progress-title, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__submitted-title {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite, .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__header {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

#donor-home-page {
  max-width: 1110px;
  margin: 64px auto; }
  #donor-home-page #donor-home-page__inner .program_filter .filter-selector .filter-selector__items .filter-selector__leading .filter-selector-item .filter-selector-item__btn {
    border: 1px solid #1C21DF;
    padding: 10px 20px;
    background: transparent;
    color: #1C21DF; }

#donor-home-page__inner {
  margin: 0 20px; }

#donor-home-page__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  @media (max-width: 768.98px) {
    #donor-home-page__top {
      flex-direction: column; } }

#donor-home-page__greeting {
  margin-bottom: 10px; }

#donor-home-page__top-programs {
  margin-bottom: 12px;
  font-family: "Inter-Medium";
  font-size: 23px;
  font-style: italic;
  color: #1E5558; }

.donor-home-page__donation-filter {
  margin: 25px 0 10px 0; }
  .donor-home-page__donation-filter #donor-home-page__top-donations {
    margin-bottom: 12px;
    font-family: "Inter-Medium";
    font-size: 23px;
    font-style: italic;
    color: #1E5558; }

#program_filter_create {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center; }
  #program_filter_create .create-scholarship-button {
    border-radius: 5px;
    padding: 17px 24px; }

#donor-home-page__main-stats,
#donor-home-page__award-stats {
  display: flex;
  width: 100%; }
  @media (max-width: 768.98px) {
    #donor-home-page__main-stats,
    #donor-home-page__award-stats {
      flex-direction: column; } }

@media (max-width: 768.98px) {
  #donor-home-page__stat-table {
    padding: 0; } }

#donor-home-page__stat-table table {
  background: #f6f6f6;
  border-radius: 24px; }
  #donor-home-page__stat-table table thead tr th {
    padding: 30px 30px 20px; }
  #donor-home-page__stat-table table tbody tr:hover {
    background: #ffffff; }
  #donor-home-page__stat-table table tr th,
  #donor-home-page__stat-table table tr td {
    padding: 10px 30px;
    font-size: 14px;
    vertical-align: middle; }
    #donor-home-page__stat-table table tr th:nth-child(2),
    #donor-home-page__stat-table table tr td:nth-child(2) {
      text-align: left; }
      #donor-home-page__stat-table table tr th:nth-child(2) .table__column-header,
      #donor-home-page__stat-table table tr td:nth-child(2) .table__column-header {
        justify-content: left; }
  #donor-home-page__stat-table table tr td:nth-child(1) {
    padding: 10px 0px 10px 30px; }
  #donor-home-page__stat-table table tr td:last-child {
    display: none; }
  #donor-home-page__stat-table table tr .progress-complete-cell-type__bar {
    background-color: #979797; }
    #donor-home-page__stat-table table tr .progress-complete-cell-type__bar.active {
      background-color: #1C21DF; }

.donor-home-page__heading-help-text {
  text-transform: none; }

.donor-home-page__large-stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #f6f6f6;
  padding: 34px 30px;
  border-radius: 5px;
  margin-right: 30px;
  width: 100%; }
  @media (max-width: 768.98px) {
    .donor-home-page__large-stat {
      margin-bottom: 15px; } }
  .donor-home-page__large-stat:last-child {
    margin-right: 0; }

.donor-home-page__large-stat-copy {
  display: flex;
  flex-direction: column; }

.donor-home-page__large-stat-amount {
  position: relative; }
  .donor-home-page__large-stat-amount sup {
    position: absolute;
    top: 5px;
    font-size: 24px; }

.donor-home-page__large-stat-value {
  margin-left: 20px; }

.donor-home-page__large-stat-label {
  margin-bottom: 10px; }

#donor-home-page__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 100px; }
  @media (max-width: 768.98px) {
    #donor-home-page__bottom {
      flex-direction: column;
      margin-top: 15px;
      margin-bottom: 15px; } }
  @media (max-width: 768.98px) {
    #donor-home-page__bottom #donor-home-page__applicants .cta-button.secondary {
      margin-bottom: 15px; } }

#donor-home-page__stat-table .date-cell-type {
  cursor: unset; }

#donor-home-page__review-progress {
  width: 75%; }
  #donor-home-page__review-progress #donor-home-page__stat-table table {
    margin-top: 10px;
    border-radius: 5px;
    margin-bottom: 10px; }
  @media (max-width: 768.98px) {
    #donor-home-page__review-progress {
      width: 100%; } }

#donor-home-page__recent {
  width: 58%; }
  @media (max-width: 768.98px) {
    #donor-home-page__recent {
      width: 100%; } }

#donor-home-page__recent-list {
  margin-bottom: 40px; }
  #donor-home-page__recent-list .notification-list__list .notification-list__item .task-list-column-1 {
    width: unset; }

#donor-home-page__applicant-stats {
  margin: 20px 0 40px; }
  @media (max-width: 768.98px) {
    #donor-home-page__applicant-stats {
      display: flex;
      margin: 20px 0 20px; } }
  @media (max-width: 768.98px) {
    #donor-home-page__applicant-stats .H1DesktopBlack {
      font-size: 30px; } }
  #donor-home-page__applicant-stats > * {
    margin-bottom: 50px; }
    @media (max-width: 768.98px) {
      #donor-home-page__applicant-stats > * {
        margin-bottom: 15px; } }
    #donor-home-page__applicant-stats > *:last-child {
      margin-bottom: 0; }

.program_filter {
  display: flex;
  align-items: center; }
  .program_filter .filter-selector.donor-home-page__filter .filter-selector__items .filter-selector__leading .filter-selector-item__btn {
    border-radius: 6px;
    padding: 12px 20px;
    background: #ffffff;
    color: #1C21DF;
    border: 1px solid #1C21DF;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .program_filter .filter-selector__heading {
    margin: 0; }
  .program_filter .filter-selector__arrow-down-icon {
    display: flex; }
  .program_filter .filter-selector__leading .filter-selector-item .filter-selector-item__btn {
    font-family: "Inter-Medium";
    font-size: 23px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1E5558;
    padding: 0; }
  .program_filter .filter-selector__leading .filter-selector-item .is-active {
    color: #ffffff;
    padding: 4px 20px;
    font-size: 18px; }

.slick-next,
.slick-prev {
  width: 40px !important;
  height: 40px !important; }

.slick-next:before,
.slick-prev:before {
  color: #696969;
  visibility: hidden; }

.scholarship-progress-tiles-container .slick-slide {
  width: 357px !important;
  box-sizing: border-box; }

.scholarship-progress-tiles-container .my-sk-slide {
  background: #ffffff;
  border-radius: 5px;
  margin: 0 20px 0 20px; }
  .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card {
    border-radius: 5px; }
    .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__header {
      background: #1E5558;
      padding: 10px 10px;
      border-radius: 8px 8px 0px 0px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      border-radius: 8px 8px 0px 0px;
      min-height: 90px;
      max-height: 90px;
      align-items: center;
      text-align: left !important; }
    .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content {
      padding: 10px 20px; }
      .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__status {
        margin-bottom: 20px; }
      .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
        .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__progress-count {
          text-align: center; }
        .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__progress-title {
          text-align: center; }
        .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__progress-divider {
          display: block;
          margin: 0 auto;
          height: 5px;
          background: #D9D9C9;
          margin-top: 10px;
          margin-bottom: 10px;
          max-width: 130px; }
        .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__submitted-count {
          text-align: center; }
        .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph__submitted-title {
          text-align: center; }
        .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph .circular {
          border-radius: 100%;
          border: 10px solid #D9D9C9;
          height: 230px;
          width: 230px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column; }
          .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph .circular .inner {
            align-items: center;
            justify-content: center;
            flex-direction: column; }
          .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph .circular .scholarship-progress-tiles-container__slice {
            position: absolute;
            width: 16em;
            height: 16em; }
          .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph .circular .ant-progress {
            width: 100%;
            height: 100%; }
          .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph .circular .ant-progress-inner {
            width: 100% !important;
            height: 100% !important; }
          .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph .circular .ant-progress-text {
            visibility: hidden; }
          .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph .circular .ant-progress-circle-trail {
            stroke: #D9D9C9; }
          .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__content__graph .circular .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
            stroke: #1E5558; }
    .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__footer {
      padding: 20px 20px;
      border-radius: 0px 0px 8px 8px; }
      .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__footer__status-indicator-list__item {
        display: flex;
        align-items: center; }
        .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__footer__status-indicator-list__item .indicator-status-draft-marker {
          width: 15px;
          height: 15px;
          background: #D9D9C9;
          border-radius: 100%;
          margin-right: 10px; }
        .scholarship-progress-tiles-container .my-sk-slide .scholarship-progress-tiles-container__card__footer__status-indicator-list__item .indicator-status-submitted-marker {
          width: 15px;
          height: 15px;
          background: #1E5558;
          border-radius: 100%;
          margin-right: 10px; }

.scholarship-progress-tiles-container .slick-track {
  display: flex;
  justify-content: center; }

.scholarship-progress-tiles-container .my-sk-slide-margin {
  margin-bottom: 30%; }

.donor-filter-container {
  margin-top: 20px;
  margin-bottom: 10px; }

.slick-track {
  display: flex;
  justify-content: center; }

.donor-donation-card-buttom {
  padding-bottom: 10%; }
