.scholarship-card-pie-container .slick-slide {
  width: 357px !important;
  box-sizing: border-box; }
  .scholarship-card-pie-container .slick-slide .scholarship-card-pie-chart-main-container {
    width: 100%;
    max-width: 400px;
    margin: auto;
    text-align: center;
    font-family: 'Inter-Medium', sans-serif;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); }
    .scholarship-card-pie-container .slick-slide .scholarship-card-pie-chart-main-container__header {
      background-color: #003f3f;
      color: #ffffff;
      padding: 10px;
      border-radius: 8px 8px 0 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      min-height: 90px;
      max-height: 90px;
      align-items: center;
      text-align: left; }
    .scholarship-card-pie-container .slick-slide .scholarship-card-pie-chart-main-container__footer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 5px;
      padding-left: 10px;
      min-height: 20px; }
      .scholarship-card-pie-container .slick-slide .scholarship-card-pie-chart-main-container__footer__color-circel {
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .scholarship-card-pie-container .slick-slide .scholarship-card-pie-chart-main-container__footer__label-content {
        font-size: 14px;
        margin-left: 5px;
        word-break: break-word;
        line-height: 1.4; }

.scholarship-card-pie-container .pie-chart-tooltip {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 12px; }

.scholarship-card-pie-container .slick-track {
  display: flex;
  justify-content: center;
  gap: 20px; }

.slick-next,
.slick-prev {
  width: 35px !important;
  height: 35px !important; }

.slick-next:before,
.slick-prev:before {
  color: #696969;
  visibility: hidden; }
